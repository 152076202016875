import { gameGroupActions } from '../actions/definition';
import { IGameGroupState } from '../interface';
import { GameGroupActionTypes } from '../interface/typesGroup';

const defaultState: IGameGroupState = {
    isLoading: false,
    data: null,
    error: null,
};

const gameGroupReducer = (state = defaultState, action: GameGroupActionTypes): IGameGroupState => {
    switch (action.type) {
        case gameGroupActions.GAME_GROUP_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case gameGroupActions.GAME_GROUP_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case gameGroupActions.GAME_GROUP_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case gameGroupActions.GAME_GROUP_EDIT:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case gameGroupActions.GAME_GROUP:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case gameGroupActions.GAME_GROUP_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case gameGroupActions.GAME_GROUP_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case gameGroupActions.GAME_GROUP_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default gameGroupReducer;
