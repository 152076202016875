import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Suspense } from 'react';

import Loading from './app/components/Commons/Loading/Loading';
import { useAuth } from './app/context/auth-context';

const loadAuthenticatedApp = () => import('./app/auth/Authenticated');
const loadAuthenticatedOwnerApp = () => import('./app/auth/Authenticated');

const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const AuthenticatedOwnerApp = React.lazy(loadAuthenticatedOwnerApp);

const UnAuthenticatedApp = React.lazy(() => import('./app/auth/UnAuthenticated'));
const Default = React.lazy(() => import('./app/auth/Default'));

function App(): React.ReactElement {
    const auth = useAuth();
    const role = auth.user?.role;

    React.useEffect(() => {
        loadAuthenticatedApp();
    });

    return (
        <>
            <CssBaseline>
                <Suspense fallback={<Loading />}>
                    {auth ? (
                        auth.error ? (
                            <Default />
                        ) : auth.loading ? (
                            <Loading />
                        ) : auth.isLoggedIn ? (
                            role === 'CLUB_OWNER' ? (
                                <AuthenticatedOwnerApp />
                            ) : (
                                <AuthenticatedApp />
                            )
                        ) : (
                            <UnAuthenticatedApp />
                        )
                    ) : (
                        <Loading />
                    )}
                </Suspense>
            </CssBaseline>
        </>
    );
}

export default App;
