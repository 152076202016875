import { fieldActions } from '../actions/definition';
import { IFieldState } from '../interface';
import { FieldActionTypes } from '../interface/types';

const defaultState: IFieldState = {
    isLoading: false,
    data: null,
    error: null,
};

const fieldReducer = (state = defaultState, action: FieldActionTypes): IFieldState => {
    switch (action.type) {
        case fieldActions.FIELD_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case fieldActions.FIELD_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case fieldActions.FIELD_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case fieldActions.FIELD:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case fieldActions.FIELD_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case fieldActions.FIELD_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case fieldActions.FIELD_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default fieldReducer;
