import { listRentActions } from '../actions/definition';
import { IListRentState } from '../interface';
import { ListRentActionTypes } from '../interface/types';

const defaultState: IListRentState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listRentReducer = (state = defaultState, action: ListRentActionTypes): IListRentState => {
    switch (action.type) {
        case listRentActions.LIST_RENT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listRentActions.LIST_RENT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listRentActions.LIST_RENT_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listRentActions.LIST_RENT:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listRentActions.LIST_RENT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listRentActions.LIST_RENT_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listRentActions.LIST_RENT_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listRentReducer;
