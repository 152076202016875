import { rentActions } from '../actions/definition';
import { IRentState } from '../interface';
import { RentActionTypes } from '../interface/types';

const defaultState: IRentState = {
    isLoading: false,
    data: null,
    error: null,
};

const rentReducer = (state = defaultState, action: RentActionTypes): IRentState => {
    switch (action.type) {
        case rentActions.RENT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case rentActions.RENT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case rentActions.RENT_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case rentActions.RENT:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case rentActions.RENT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case rentActions.RENT_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case rentActions.RENT_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default rentReducer;
