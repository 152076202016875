/**
 * Model actions define
 */

export const reservationActions = {
    RESERVATION_IS_LOADING: 'RESERVATION_IS_LOADING',
    RESERVATION_ERROR: 'RESERVATION_ERROR',
    RESERVATION_RESET: 'RESERVATION_RESET',
    RESERVATION_NEW: 'RESERVATION_NEW',
    RESERVATION: 'RESERVATION',
    RESERVATION_LIST: 'RESERVATION_LIST',
    RESERVATION_GET: 'RESERVATION_GET',
    RESERVATION_REMOVE: 'RESERVATION_REMOVE',
    RESERVATION_EXPORT: 'RESERVATION_EXPORT',
};

export const listReservationActions = {
    LIST_RESERVATION_IS_LOADING: 'LIST_RESERVATION_IS_LOADING',
    LIST_RESERVATION_ERROR: 'LIST_RESERVATION_ERROR',
    LIST_RESERVATION_RESET: 'LIST_RESERVATION_RESET',
    LIST_RESERVATION_NEW: 'LIST_RESERVATION_NEW',
    LIST_RESERVATION: 'LIST_RESERVATION',
    LIST_RESERVATION_LIST: 'LIST_RESERVATION_LIST',
    LIST_RESERVATION_GET: 'LIST_RESERVATION_GET',
    LIST_RESERVATION_REMOVE: 'LIST_RESERVATION_REMOVE',
};
