/**
 * Model actions define
 */

export const broadcastActions = {
    BROADCAST_IS_LOADING: 'BROADCAST_IS_LOADING',
    BROADCAST_ERROR: 'BROADCAST_ERROR',
    BROADCAST_RESET: 'BROADCAST_RESET',
    BROADCAST_CREATED: 'BROADCAST_CREATED',
    BROADCAST: 'BROADCAST',
    BROADCAST_LIST: 'BROADCAST_LIST',
    BROADCAST_BY_ID: 'BROADCAST_BY_ID',
    BROADCAST_DELETED: 'BROADCAST_DELETED',
    BROADCAST_SEARCH: 'BROADCAST_SEARCH',
    BROADCAST_UPDATED: 'BROADCAST_UPDATED',
};

export const listBroadcastActions = {
    LIST_BROADCAST_IS_LOADING: 'LIST_BROADCAST_IS_LOADING',
    LIST_BROADCAST_ERROR: 'LIST_BROADCAST_ERROR',
    LIST_BROADCAST_RESET: 'LIST_BROADCAST_RESET',
    LIST_BROADCAST_CREATED: 'LIST_BROADCAST_CREATED',
    LIST_BROADCAST: 'LIST_BROADCAST',
    LIST_BROADCAST_LIST: 'LIST_BROADCAST_LIST',
    LIST_BROADCAST_BY_ID: 'LIST_BROADCAST_BY_ID',
    LIST_BROADCAST_DELETED: 'LIST_BROADCAST_DELETED',
    BROADCAST_LISTED: ' BROADCAST_LISTED',
};
