/**
 * Model actions define
 */

export const defyActions = {
    DEFY_IS_LOADING: 'DEFY_IS_LOADING',
    DEFY_ERROR: 'DEFY_ERROR',
    DEFY_RESET: 'DEFY_RESET',
    DEFY_NEW: 'DEFY_NEW',
    DEFY: 'DEFY',
    DEFY_LIST: 'DEFY_LIST',
    DEFY_GET: 'DEFY_GET',
    DEFY_REMOVE: 'DEFY_REMOVE',
    DEFY_EXPORT: 'DEFY_EXPORT',
};

export const listDefyActions = {
    LIST_DEFY_IS_LOADING: 'LIST_DEFY_IS_LOADING',
    LIST_DEFY_ERROR: 'LIST_DEFY_ERROR',
    LIST_DEFY_RESET: 'LIST_DEFY_RESET',
    LIST_DEFY_NEW: 'LIST_DEFY_NEW',
    LIST_DEFY: 'LIST_DEFY',
    LIST_DEFY_LIST: 'LIST_DEFY_LIST',
    LIST_DEFY_GET: 'LIST_DEFY_GET',
    LIST_DEFY_REMOVE: 'LIST_DEFY_REMOVE',
};

export const defyGroupActions = {
    DEFY_GROUP_IS_LOADING: 'DEFY_GROUP_IS_LOADING',
    DEFY_GROUP_ERROR: 'DEFY_GROUP_ERROR',
    DEFY_GROUP_RESET: 'DEFY_GROUP_RESET',
    DEFY_GROUP_NEW: 'DEFY_GROUP_NEW',
    DEFY_GROUP_EDIT: 'DEFY_GROUP_EDIT',
    DEFY_GROUP: 'DEFY_GROUP',
    DEFY_GROUP_LIST: 'DEFY_GROUP_LIST',
    DEFY_GROUP_GET: 'DEFY_GROUP_GET',
    DEFY_GROUP_REMOVE: 'DEFY_GROUP_REMOVE',
};

export const listDefyGroupActions = {
    LIST_DEFY_GROUP_IS_LOADING: 'LIST_DEFY_GROUP_IS_LOADING',
    LIST_DEFY_GROUP_ERROR: 'LIST_DEFY_GROUP_ERROR',
    LIST_DEFY_GROUP_RESET: 'LIST_DEFY_GROUP_RESET',
    LIST_DEFY_GROUP_NEW: 'LIST_DEFY_GROUP_NEW',
    LIST_DEFY_GROUP: 'LIST_DEFY_GROUP',
    LIST_DEFY_GROUP_LIST: 'LIST_DEFY_GROUP_LIST',
    LIST_DEFY_GROUP_GET: 'LIST_DEFY_GROUP_GET',
    LIST_DEFY_GROUP_REMOVE: 'LIST_DEFY_GROUP_REMOVE',
};

export const defyPlayerActions = {
    DEFY_PLAYER_IS_LOADING: 'DEFY_PLAYER_IS_LOADING',
    DEFY_PLAYER_ERROR: 'DEFY_PLAYER_ERROR',
    DEFY_PLAYER_RESET: 'DEFY_PLAYER_RESET',
    DEFY_PLAYER_CANCEL: 'DEFY_PLAYER_CANCEL',
};
