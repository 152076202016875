import { gamePlayerActions } from '../actions/definition';
import { IGamePlayerState } from '../interface';
import { GamePlayerActionTypes } from '../interface/types';

const defaultState: IGamePlayerState = {
    isLoading: false,
    data: null,
    error: null,
};

const gamePlayerReducer = (state = defaultState, action: GamePlayerActionTypes): IGamePlayerState => {
    switch (action.type) {
        case gamePlayerActions.GAME_PLAYER_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case gamePlayerActions.GAME_PLAYER_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case gamePlayerActions.GAME_PLAYER_CANCEL:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case gamePlayerActions.GAME_PLAYER_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        default:
            return state;
    }
};

export default gamePlayerReducer;
