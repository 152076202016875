import { regionActions, countyActions } from '../actions/definition';
import { IRegionState } from '../interface';
import { RegionActionTypes } from '../interface/types';

const defaultState: IRegionState = {
    isLoading: false,
    data: null,
    error: null,
};

export const regionReducer = (state = defaultState, action: RegionActionTypes): IRegionState => {
    switch (action.type) {
        case regionActions.REGION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case regionActions.REGION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case regionActions.REGION_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case regionActions.REGION:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case regionActions.REGION_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case regionActions.REGION_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case regionActions.REGION_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export const countyReducer = (state = defaultState, action: RegionActionTypes): IRegionState => {
    switch (action.type) {
        case countyActions.COUNTY_CLER:
            return {
                isLoading: false,
                data: null,
                error: null,
            };
        case countyActions.COUNTY_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case countyActions.COUNTY_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case countyActions.COUNTY_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case countyActions.COUNTY:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case countyActions.COUNTY_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case countyActions.COUNTY_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case countyActions.COUNTY_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};
