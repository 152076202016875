/**
 * Model actions define
 */

export const rankingActions = {
    RANKING_IS_LOADING: 'RANKING_IS_LOADING',
    RANKING_ERROR: 'RANKING_ERROR',
    RANKING_RESET: 'RANKING_RESET',
    RANKING_NEW: 'RANKING_NEW',
    RANKING: 'RANKING',
    RANKING_LIST: 'RANKING_LIST',
    RANKING_GET: 'RANKING_GET',
    RANKING_REMOVE: 'RANKING_REMOVE',
};

export const listRankingActions = {
    LIST_RANKING_IS_LOADING: 'LIST_RANKING_IS_LOADING',
    LIST_RANKING_ERROR: 'LIST_RANKING_ERROR',
    LIST_RANKING_RESET: 'LIST_RANKING_RESET',
    LIST_RANKING_NEW: 'LIST_RANKING_NEW',
    LIST_RANKING: 'LIST_RANKING',
    LIST_RANKING_LIST: 'LIST_RANKING_LIST',
    LIST_RANKING_GET: 'LIST_RANKING_GET',
    LIST_RANKING_REMOVE: 'LIST_RANKING_REMOVE',
};

export const listDefyActions = {
    LIST_DEFY_IS_LOADING: 'LIST_DEFY_IS_LOADING',
    LIST_DEFY_ERROR: 'LIST_DEFY_ERROR',
    LIST_DEFY_RESET: 'LIST_DEFY_RESET',
    LIST_DEFY_NEW: 'LIST_DEFY_NEW',
    LIST_DEFY: 'LIST_DEFY',
    LIST_DEFY_LIST: 'LIST_DEFY_LIST',
    LIST_DEFY_GET: 'LIST_DEFY_GET',
    LIST_DEFY_REMOVE: 'LIST_DEFY_REMOVE',
};
