import { listBroadcastActions } from '../actions/definition';
import { IListBroadcastState } from '../interface';
import { ListBroadcastActionTypes } from '../interface/types';

const defaultState: IListBroadcastState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listTransactionReducer = (state = defaultState, action: ListBroadcastActionTypes): IListBroadcastState => {
    switch (action.type) {
        case listBroadcastActions.LIST_BROADCAST_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listBroadcastActions.LIST_BROADCAST_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listBroadcastActions.LIST_BROADCAST_CREATED:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listBroadcastActions.LIST_BROADCAST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listBroadcastActions.LIST_BROADCAST_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listBroadcastActions.LIST_BROADCAST_BY_ID:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listBroadcastActions.LIST_BROADCAST_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listTransactionReducer;
