/**
 * Model actions define
 */

export const playerActions = {
    PLAYER_IS_LOADING: 'PLAYER_IS_LOADING',
    PLAYER_ERROR: 'PLAYER_ERROR',
    PLAYER_RESET: 'PLAYER_RESET',
    PLAYER_CREATED: 'PLAYER_CREATED',
    PLAYER: 'PLAYER',
    PLAYER_LIST: 'PLAYER_LIST',
    PLAYER_BY_ID: 'PLAYER_BY_ID',
    PLAYER_DELETED: 'PLAYER_DELETED',
    PLAYER_SEARCH: 'PLAYER_SEARCH',
    PLAYER_UPDATED: 'PLAYER_UPDATED',
};

export const listPlayerActions = {
    LIST_PLAYER_IS_LOADING: 'LIST_PLAYER_IS_LOADING',
    LIST_PLAYER_ERROR: 'LIST_PLAYER_ERROR',
    LIST_PLAYER_RESET: 'LIST_PLAYER_RESET',
    LIST_PLAYER_CREATED: 'LIST_PLAYER_CREATED',
    LIST_PLAYER: 'LIST_PLAYER',
    LIST_PLAYER_LIST: 'LIST_PLAYER_LIST',
    LIST_PLAYER_BY_ID: 'LIST_PLAYER_BY_ID',
    LIST_PLAYER_DELETED: 'LIST_PLAYER_DELETED',
    PLAYER_LISTED: ' PLAYER_LISTED',
};
