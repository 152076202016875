/**
 * Model actions define
 */

export const systemConfigActions = {
    SYSTEM_CONFIG_IS_LOADING: 'SYSTEM_CONFIG_IS_LOADING',
    SYSTEM_CONFIG_ERROR: 'SYSTEM_CONFIG_ERROR',
    SYSTEM_CONFIG_RESET: 'SYSTEM_CONFIG_RESET',
    SYSTEM_CONFIG_CREATED: 'SYSTEM_CONFIG_CREATED',
    SYSTEM_CONFIG: 'SYSTEM_CONFIG',
    SYSTEM_CONFIG_LIST: 'SYSTEM_CONFIG_LIST',
    SYSTEM_CONFIG_BY_ID: 'SYSTEM_CONFIG_BY_ID',
    SYSTEM_CONFIG_DELETED: 'SYSTEM_CONFIG_DELETED',
    SYSTEM_CONFIG_SEARCH: 'SYSTEM_CONFIG_SEARCH',
    SYSTEM_CONFIG_UPDATED: 'SYSTEM_CONFIG_UPDATED',
};

export const listSystemConfigActions = {
    LIST_SYSTEM_CONFIG_IS_LOADING: 'LIST_SYSTEM_CONFIG_IS_LOADING',
    LIST_SYSTEM_CONFIG_ERROR: 'LIST_SYSTEM_CONFIG_ERROR',
    LIST_SYSTEM_CONFIG_RESET: 'LIST_SYSTEM_CONFIG_RESET',
    LIST_SYSTEM_CONFIG_CREATED: 'LIST_SYSTEM_CONFIG_CREATED',
    LIST_SYSTEM_CONFIG: 'LIST_SYSTEM_CONFIG',
    LIST_SYSTEM_CONFIG_LIST: 'LIST_SYSTEM_CONFIG_LIST',
    LIST_SYSTEM_CONFIG_BY_ID: 'LIST_SYSTEM_CONFIG_BY_ID',
    LIST_SYSTEM_CONFIG_DELETED: 'LIST_SYSTEM_CONFIG_DELETED',
    SYSTEM_CONFIG_LISTED: ' SYSTEM_CONFIG_LISTED',
};
