import { listReservationActions } from '../actions/definition';
import { IListReservationState } from '../interface';
import { ListReservationActionTypes } from '../interface/types';

const defaultState: IListReservationState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listReservationsReducer = (state = defaultState, action: ListReservationActionTypes): IListReservationState => {
    switch (action.type) {
        case listReservationActions.LIST_RESERVATION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listReservationActions.LIST_RESERVATION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listReservationActions.LIST_RESERVATION_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listReservationActions.LIST_RESERVATION:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listReservationActions.LIST_RESERVATION_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listReservationActions.LIST_RESERVATION_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listReservationActions.LIST_RESERVATION_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listReservationsReducer;
