import React, { createContext, useContext } from 'react';

import { useProvideAuthAws } from '../config/provider/aws/services';
import { IContext, IUser } from '../interfaces';

const user: IUser = {
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    photoURL: '',
    uid: '',
    role: '',
    firstLogin: false,
};

const context: IContext = {
    user: user,
    isLoggedIn: null,
    loading: true,
    error: null,
    token: null,
    isNewUser: false,
    sendedForgot: false,
    emailForgot: null,
    passwordOk: false,
    loginEmail: async () =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        new Promise<any>(() => {
            return '';
        }),
    register: async () =>
        new Promise<void>(() => {
            return '';
        }),
    logout: async () =>
        new Promise<void>(() => {
            return '';
        }),
    confirmCode: async () =>
        new Promise<void>(() => {
            return '';
        }),
    changePassword: async () =>
        new Promise<void>(() => {
            return '';
        }),
    forgotConfirm: async () =>
        new Promise<void>(() => {
            return '';
        }),
    forgotCode: async () =>
        new Promise<void>(() => {
            return '';
        }),
};

export const UserContext = createContext<IContext>(context);

export function UserProvider(props: { children: React.ReactNode }): JSX.Element {
    const { children } = props;
    let auth = context;
    auth = useProvideAuthAws();
    return <UserContext.Provider value={auth} children={children} />;
}

export const useAuth = (): IContext => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw Error(`useAuth must be used within a ProvideAuth`);
    }
    return useContext(UserContext);
};
