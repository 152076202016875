import { listGameGroupActions } from '../actions/definition';
import { IListGameGroupState } from '../interface';
import { ListGameGroupActionTypes } from '../interface/typesGroup';

const defaultState: IListGameGroupState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listGameGroupReducer = (state = defaultState, action: ListGameGroupActionTypes): IListGameGroupState => {
    switch (action.type) {
        case listGameGroupActions.LIST_GAME_GROUP_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listGameGroupActions.LIST_GAME_GROUP_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listGameGroupActions.LIST_GAME_GROUP_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listGameGroupActions.LIST_GAME_GROUP:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listGameGroupActions.LIST_GAME_GROUP_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listGameGroupActions.LIST_GAME_GROUP_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listGameGroupActions.LIST_GAME_GROUP_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listGameGroupReducer;
