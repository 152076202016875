/**
 * Model actions define
 */

export const fieldActions = {
    FIELD_IS_LOADING: 'FIELD_IS_LOADING',
    FIELD_ERROR: 'FIELD_ERROR',
    FIELD_RESET: 'FIELD_RESET',
    FIELD_NEW: 'FIELD_NEW',
    FIELD: 'FIELD',
    FIELD_LIST: 'FIELD_LIST',
    FIELD_GET: 'FIELD_GET',
    FIELD_REMOVE: 'FIELD_REMOVE',
};

export const listFieldActions = {
    LIST_FIELD_IS_LOADING: 'LIST_FIELD_IS_LOADING',
    LIST_FIELD_ERROR: 'LIST_FIELD_ERROR',
    LIST_FIELD_RESET: 'LIST_FIELD_RESET',
    LIST_FIELD_NEW: 'LIST_FIELD_NEW',
    LIST_FIELD: 'LIST_FIELD',
    LIST_FIELD_LIST: 'LIST_FIELD_LIST',
    LIST_FIELD_GET: 'LIST_FIELD_GET',
    LIST_FIELD_REMOVE: 'LIST_FIELD_REMOVE',
};
