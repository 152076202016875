/**
 * Model actions define
 */

export const regionActions = {
    REGION_IS_LOADING: 'REGION_IS_LOADING',
    REGION_ERROR: 'REGION_ERROR',
    REGION_RESET: 'REGION_RESET',
    REGION_NEW: 'REGION_NEW',
    REGION: 'REGION',
    REGION_LIST: 'REGION_LIST',
    REGION_GET: 'REGION_GET',
    REGION_REMOVE: 'REGION_REMOVE',
    REGIONS_LISTED: 'REGIONS_LISTED',
};

export const countyActions = {
    COUNTY_IS_LOADING: 'COUNTY_IS_LOADING',
    COUNTY_ERROR: 'COUNTY_ERROR',
    COUNTY_RESET: 'COUNTY_RESET',
    COUNTY_NEW: 'COUNTY_NEW',
    COUNTY: 'COUNTY',
    COUNTY_LIST: 'COUNTY_LIST',
    COUNTY_GET: 'COUNTY_GET',
    COUNTY_REMOVE: 'COUNTY_REMOVE',
    COUNTY_CLER: 'COUNTY_CLER',
};
