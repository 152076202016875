import { StoreEnhancer } from 'redux';

/*const round = (number: number) => Math.round(number * 100) / 100;*/

const monitorReducerEnhancer = (
    createStore: (arg0: (state: never, action: never) => never, arg1: never, arg2: never) => StoreEnhancer,
) => (reducer: (arg0: never, arg1: never) => never, initialState: never, enhancer: never): StoreEnhancer => {
    const monitoredReducer = (state: never, action: never) => {
        const newState = reducer(state, action);
        /*const start = performance.now();
        const end = performance.now();
        const diff = round(end - start);

        console.log('reducer process time:', diff);*/

        return newState;
    };

    return createStore(monitoredReducer, initialState, enhancer);
};

export default monitorReducerEnhancer;
