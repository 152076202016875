/**
 * Model actions define
 */

export const clubActions = {
    CLUB_IS_LOADING: 'CLUB_IS_LOADING',
    CLUB_ERROR: 'CLUB_ERROR',
    CLUB_RESET: 'CLUB_RESET',
    CLUB_NEW: 'CLUB_NEW',
    CLUB: 'CLUB',
    CLUB_LIST: 'CLUB_LIST',
    CLUB_GET: 'CLUB_GET',
    CLUB_REMOVE: 'CLUB_REMOVE',
};

export const listClubActions = {
    LIST_CLUB_IS_LOADING: 'LIST_CLUB_IS_LOADING',
    LIST_CLUB_ERROR: 'LIST_CLUB_ERROR',
    LIST_CLUB_RESET: 'LIST_CLUB_RESET',
    LIST_CLUB_NEW: 'LIST_CLUB_NEW',
    LIST_CLUB: 'LIST_CLUB',
    LIST_CLUB_LIST: 'LIST_CLUB_LIST',
    LIST_CLUB_GET: 'LIST_CLUB_GET',
    LIST_CLUB_REMOVE: 'LIST_CLUB_REMOVE',
};

export const statusPayment = {
    PENDING_PAYMENT: 'PENDIENTE',
    PAID_OUT: 'PAGADO',
    CANCEL: 'CANCELADO',
};

export const statusChallenge = {
    ACCEPTED: 'ACEPTADO',
    WAITING_TO_ACCEPT: 'PENDIENTE POR ACEPTAR',
};

export const typeChallenge = {
    OPEN: 'ABIERTO',
    DIRECT: 'DIRIGIDO',
};
