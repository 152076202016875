import { invitationActions } from '../actions/definition';
import { IInvitationState } from '../interface';
import { InvitationActionTypes } from '../interface/types';

const defaultState: IInvitationState = {
    isLoading: false,
    data: null,
    error: null,
};

const invitationReducer = (state = defaultState, action: InvitationActionTypes): IInvitationState => {
    switch (action.type) {
        case invitationActions.INVITATION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case invitationActions.INVITATION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case invitationActions.INVITATION_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case invitationActions.INVITATION:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case invitationActions.INVITATION_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case invitationActions.INVITATION_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case invitationActions.INVITATION_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default invitationReducer;
