/**
 * Model actions define
 */

export const rentActions = {
    RENT_IS_LOADING: 'RENT_IS_LOADING',
    RENT_ERROR: 'RENT_ERROR',
    RENT_RESET: 'RENT_RESET',
    RENT_NEW: 'RENT_NEW',
    RENT: 'RENT',
    RENT_LIST: 'RENT_LIST',
    RENT_GET: 'RENT_GET',
    RENT_REMOVE: 'RENT_REMOVE',
};

export const listRentActions = {
    LIST_RENT_IS_LOADING: 'LIST_RENT_IS_LOADING',
    LIST_RENT_ERROR: 'LIST_RENT_ERROR',
    LIST_RENT_RESET: 'LIST_RENT_RESET',
    LIST_RENT_NEW: 'LIST_RENT_NEW',
    LIST_RENT: 'LIST_RENT',
    LIST_RENT_LIST: 'LIST_RENT_LIST',
    LIST_RENT_GET: 'LIST_RENT_GET',
    LIST_RENT_REMOVE: 'LIST_RENT_REMOVE',
};

export const rentGroupActions = {
    RENT_GROUP_IS_LOADING: 'RENT_GROUP_IS_LOADING',
    RENT_GROUP_ERROR: 'RENT_GROUP_ERROR',
    RENT_GROUP_RESET: 'RENT_GROUP_RESET',
    RENT_GROUP_NEW: 'RENT_GROUP_NEW',
    RENT_GROUP_EDIT: 'RENT_GROUP_EDIT',
    RENT_GROUP: 'RENT_GROUP',
    RENT_GROUP_LIST: 'RENT_GROUP_LIST',
    RENT_GROUP_GET: 'RENT_GROUP_GET',
    RENT_GROUP_REMOVE: 'RENT_GROUP_REMOVE',
};

export const listRentGroupActions = {
    LIST_RENT_GROUP_IS_LOADING: 'LIST_RENT_GROUP_IS_LOADING',
    LIST_RENT_GROUP_ERROR: 'LIST_RENT_GROUP_ERROR',
    LIST_RENT_GROUP_RESET: 'LIST_RENT_GROUP_RESET',
    LIST_RENT_GROUP_NEW: 'LIST_RENT_GROUP_NEW',
    LIST_RENT_GROUP: 'LIST_RENT_GROUP',
    LIST_RENT_GROUP_LIST: 'LIST_RENT_GROUP_LIST',
    LIST_RENT_GROUP_GET: 'LIST_RENT_GROUP_GET',
    LIST_RENT_GROUP_REMOVE: 'LIST_RENT_GROUP_REMOVE',
};
