/**
 * Model actions define
 */

export const teamActions = {
    TEAM_IS_LOADING: 'TEAM_IS_LOADING',
    TEAM_ERROR: 'TEAM_ERROR',
    TEAM_RESET: 'TEAM_RESET',
    TEAM_NEW: 'TEAM_NEW',
    TEAM: 'TEAM',
    TEAM_LIST: 'TEAM_LIST',
    TEAM_GET: 'TEAM_GET',
    TEAM_REMOVE: 'TEAM_REMOVE',
    TEAMS_LISTED: 'TEAMS_LISTED',
};
