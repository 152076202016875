import { listDefyActions } from '../actions/definition';
import { IListDefyState } from '../interface';
import { ListDefyActionTypes } from '../interface/types';

const defaultState: IListDefyState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listDefyReducer = (state = defaultState, action: ListDefyActionTypes): IListDefyState => {
    switch (action.type) {
        case listDefyActions.LIST_DEFY_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listDefyActions.LIST_DEFY_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listDefyActions.LIST_DEFY_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listDefyActions.LIST_DEFY:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listDefyActions.LIST_DEFY_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listDefyActions.LIST_DEFY_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listDefyActions.LIST_DEFY_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listDefyReducer;
