/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'aws-amplify';

import configAuth from '../config/auth/index';
import { IGetReturn } from '../interfaces';

const setParameters = (url?: string, params?: string): string => {
    let withToken = `${url}`;
    if (params && Object.keys(params).length) {
        const queryParams = Object.keys(params).reduce((par, key) => (par += `&${key}=${params[key]}`));
        withToken = withToken + queryParams;
    }
    return withToken;
};

const get = async (model: string, options?: { id: string; params: string }): Promise<any> => {
    const { id, params } = options || {};
    return await API.get(configAuth.apiGateway.NAME, setParameters(`/${model}${id ? '/' + id : ''}`, params), {});
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const create = (model: string) => async (body: any): Promise<any> => {
    return await API.post(configAuth.apiGateway.NAME, `/${model}`, {
        body,
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const update = (model: string) => async (body: any): Promise<any> => {
    return await API.put(configAuth.apiGateway.NAME, `/${model}`, {
        method: 'PUT',
        body,
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updatePath = (model: string) => async (body: any): Promise<any> => {
    return await API.patch(configAuth.apiGateway.NAME, `/${model}`, {
        method: 'PATCH',
        body,
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const remove = async (model: string): Promise<IGetReturn> => {
    return await API.del(configAuth.apiGateway.NAME, `/${model}`, {});
};

export const getModel = get;
export const createModel = create;
export const updateModel = update;
export const updatePathModel = updatePath;
export const postModel = create;
export const deleteModel = remove;
