import { listInvitationActions } from '../actions/definition';
import { IListInvitationState } from '../interface';
import { ListInvitationActionTypes } from '../interface/types';

const defaultState: IListInvitationState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listTransactionReducer = (state = defaultState, action: ListInvitationActionTypes): IListInvitationState => {
    switch (action.type) {
        case listInvitationActions.LIST_INVITATION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listInvitationActions.LIST_INVITATION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listInvitationActions.LIST_INVITATION_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listInvitationActions.LIST_INVITATION:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listInvitationActions.LIST_INVITATION_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listInvitationActions.LIST_INVITATION_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listInvitationActions.LIST_INVITATION_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listTransactionReducer;
