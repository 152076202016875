import { combineReducers } from 'redux';

import clubReducer from '../modules/club/reducers/index';
import listClubReducer from '../modules/club/reducers/list';
import fieldReducer from '../modules/field/reducers';
import listFieldsReducer from '../modules/field/reducers/list';
import invitationReducer from '../modules/invitation/reducers/index';
import reservationReducer from '../modules/reservation/reducers/index';
import listInvitationReducer from '../modules/invitation/reducers/list';
import listReservationsReducer from '../modules/reservation/reducers/list';
import gameReducer from '../modules/game/reducers';
import listGameReducer from '../modules/game/reducers/list';
import { countyReducer, regionReducer } from '../modules/regionsCounties/reducers/index';
import gameGroupReducer from '../modules/game/reducers/index-group';
import listGameGroupReducer from '../modules/game/reducers/list-group';
import rentReducer from '../modules/rent/reducers';
import listRentReducer from '../modules/rent/reducers/list';
import gamePlayerReducer from '../modules/game/reducers/gamePlayer';
import listDefyReducer from '../modules/defy/reducers/list';
import defyReducer from '../modules/defy/reducers';
import broadcastReducer from '../modules/broadcast/reducers/index';
import listBroadcastReducer from '../modules/broadcast/reducers/list';
import systemConfigReducer from '../modules/systemConfig/reducers/index';
import playerReducer from '../modules/players/reducers/index';
import listPlayersReducer from '../modules/players/reducers/list';
import { teamReducer } from '../modules/teams/reducers/index';
import listChallengesGroupReducer from '../modules/defy/reducers/listChallengesGroup';
import rankingReducer from '../modules/ranking/reducers/index';
const rootReducer = combineReducers({
    invitations: invitationReducer,
    listInvitations: listInvitationReducer,
    clubs: clubReducer,
    listClubs: listClubReducer,
    listGames: listGameReducer,
    fields: fieldReducer,
    games: gameReducer,
    listFields: listFieldsReducer,
    reservations: reservationReducer,
    listReservations: listReservationsReducer,
    regions: regionReducer,
    counties: countyReducer,
    gamesGroup: gameGroupReducer,
    listGamesGroup: listGameGroupReducer,
    rents: rentReducer,
    listRents: listRentReducer,
    gamePlayer: gamePlayerReducer,
    listDefys: listDefyReducer,
    defys: defyReducer,
    broadcasts: broadcastReducer,
    listBroadcasts: listBroadcastReducer,
    systemConfigs: systemConfigReducer,
    players: playerReducer,
    listPlayers: listPlayersReducer,
    teams: teamReducer,
    listChallengesGroup: listChallengesGroupReducer,
    ranking: rankingReducer,
});

export default rootReducer;
