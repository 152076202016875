import { teamActions } from '../actions/definition';
import { ITeamState } from '../interface';
import { TeamActionTypes } from '../interface/types';

const defaultState: ITeamState = {
    isLoading: false,
    data: null,
    error: null,
};

export const teamReducer = (state = defaultState, action: TeamActionTypes): ITeamState => {
    switch (action.type) {
        case teamActions.TEAM_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case teamActions.TEAM_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case teamActions.TEAM_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case teamActions.TEAM:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case teamActions.TEAM_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case teamActions.TEAM_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case teamActions.TEAM_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};
