import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import { fontFamily } from './index';

export const themeConfig = {
    typography: {
        fontFamily: [fontFamily].join(','),
        fontWeight: 400,
        fontSize: 12,
    },
    palette: {
        primary: {
            light: '#484c6f',
            main: '#1B204B',
            dark: '#121634',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#6dc4ad',
            main: '#49b699',
            dark: '#337f6b',
            contrastText: '#000000',
        },
        error: {
            light: red[50],
            main: red[500],
            dark: red[700],
            contrastText: '#000',
        },
        warning: {
            light: amber[50],
            main: amber[500],
            dark: amber[700],
            contrastText: '#000',
        },
        info: {
            light: '#f8fafc',
            main: '#f7f9fc',
            dark: '#acaeb0',
            contrastText: '#000',
        },
        success: {
            light: green[50],
            main: green[200],
            dark: green[400],
            contrastText: '#000',
        },
    },
    // eslint-disable-next-line prettier/prettier
};
