import { systemConfigActions } from '../actions/definition';
import { ISystemConfigState } from '../interface';
import { SystemConfigActionTypes } from '../interface/types';

const defaultState: ISystemConfigState = {
    isLoading: false,
    data: null,
    error: null,
};

const systemConfigReducer = (state = defaultState, action: SystemConfigActionTypes): ISystemConfigState => {
    switch (action.type) {
        case systemConfigActions.SYSTEM_CONFIG_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case systemConfigActions.SYSTEM_CONFIG_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case systemConfigActions.SYSTEM_CONFIG_CREATED:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case systemConfigActions.SYSTEM_CONFIG:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case systemConfigActions.SYSTEM_CONFIG_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case systemConfigActions.SYSTEM_CONFIG_BY_ID:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case systemConfigActions.SYSTEM_CONFIG_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default systemConfigReducer;
