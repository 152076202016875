/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { IContext, IError, IUser, IUserReturn } from '../../../interfaces';
import { postModel } from '../../../services/api.service';
import AuthClient from './index';

export const LoginApi = async (): Promise<IUserReturn> => {
    const result = await postModel('users/login')({});
    return result;
};

export const useProvideAuthAws = (): IContext => {
    const [isLoggedIn, setLoggedIn] = useState<boolean | null>(null);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError | null>(null);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [user, setUser] = useState<IUser | null | undefined>();
    const [token, setToken] = useState<string | null | undefined>(null);
    const [sendedForgot, setSendedForgot] = useState<boolean>(false);
    const [emailForgot, setEmailForgot] = useState<string | null>(null);
    const [passwordOk, setPasswordOk] = useState<boolean>(false);

    const getToken = async (): Promise<void> => {
        try {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            setToken(token);
        } catch (error: any) {
            setError({
                statusCode: error.code,
                message: error.message,
                error: error.message,
            });
        }
    };

    const getUser = async (): Promise<void> => {
        setError(null);
        try {
            const resultLogin = await LoginApi();
            if (resultLogin && resultLogin.data && resultLogin.statusCode === 'USER_LOGGED_IN') {
                const login = resultLogin && resultLogin.data ? resultLogin.data : null;
                if (login && login.role && (login.role === 'ADMIN' || login.role === 'CLUB_OWNER')) {
                    const user = await Auth.currentUserInfo();
                    if (user != null) {
                        getToken();
                        const auth = {
                            email: login.email ? login.email : user.attributes['email'] ? user.attributes['email'] : '',
                            firstName: login.name
                                ? login.name
                                : user.attributes['given_name']
                                ? user.attributes['given_name']
                                : '',
                            lastName: login.lastName
                                ? login.lastName
                                : user.attributes['family_name']
                                ? user.attributes['family_name']
                                : '',
                            fullName: user.attributes['name'] ? user.attributes['name'] : '',
                            photoURL: '#',
                            uid: user.attributes['sub'] ? user.attributes['sub'] : '',
                            role: login.role,
                            userId: login.id, // user id in database
                        };
                        if (login.firstLogin) {
                            setIsNewUser(login.firstLogin);
                        }

                        setUser(auth);
                        setLoggedIn(true);
                        setIsLoading(false);
                    }
                } else {
                    setError({
                        statusCode: 'UNAUTHORIZED_USER',
                        message: 'Usuario No autorizado',
                        error: 'unauthorized user',
                    });
                    setIsLoading(false);
                    logout();
                }
            } else {
                console.log('resultLogin error:', resultLogin);
                setError({
                    statusCode: resultLogin.statusCode,
                    message: resultLogin.message,
                    error: resultLogin.error!,
                });
                setIsLoading(false);
                logout();
            }
        } catch (error: any) {
            console.log('aqui error getUser:', error);
            if (error && !error.code) {
                setError({
                    statusCode: 'ErrorConection',
                    message: error.message,
                    error: error.message,
                });
            } else {
                setError({
                    statusCode: error.code,
                    message: error.message,
                    error: error.message,
                });
            }

            setIsLoading(false);
            logout();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loginEmail = async (email: string, password: string): Promise<any> => {
        setError(null);
        try {
            setIsLoading(true);
            const user = await Auth.signIn(email.toLowerCase().trim(), password);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                console.log('MFA code challenge');
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log('New password required');
            } else if (user.challengeName === 'MFA_SETUP') {
                console.log('MFA_SETUP');
                Auth.setupTOTP(user);
            } else {
                if (user.challengeName !== undefined) {
                    console.log('challengeName:', user.challengeName);
                }
            }
            await getUser();
            return user;
        } catch (error: any) {
            console.log('error loginEmail:', error);
            setError({
                statusCode: error.code,
                message: error.message,
                error: error.message,
            });
            setIsLoading(false);
        }
    };

    const register = async (email: string, password: string, firstName: string, lastName: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            await AuthClient.register(email, password, firstName, lastName).then(() => {
                setIsLoading(false);
            });
        } catch (error: any) {
            console.log('error register:', error);
            setError({
                statusCode: error.code,
                message: error.message,
                error: error.message,
            });
            setIsLoading(false);
            logout();
        }
    };

    const changePassword = async (email: string, password: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            const user = await Auth.signIn(email.toLowerCase().trim(), password);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                console.log('MFA code challenge');
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log('New password required');
                Auth.completeNewPassword(user, password)
                    .then((user) => {
                        console.log('completeNewPassword', user);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            } else if (user.challengeName === 'MFA_SETUP') {
                console.log('MFA_SETUP');
                Auth.setupTOTP(user);
            } else {
                if (user.challengeName !== undefined) {
                    console.log('challengeName:', user.challengeName);
                }
            }
            getUser();
        } catch (error: any) {
            console.log('error changePassword:', error);
            setError({
                statusCode: error.code,
                message: error.message,
                error: error.message,
            });
            setIsLoading(false);
            logout();
        }
    };

    const logout = async (): Promise<void> => {
        try {
            await AuthClient.signOut();
            setLoggedIn(false);
        } catch (error: any) {
            console.log('error logout:', error);
            setError({
                statusCode: error.code,
                message: error.message,
                error: error.message,
            });
            setLoggedIn(false);
        }
    };

    const confirmCode = async (email: string, code: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            const confirm = await AuthClient.confirmRegister(email, code).then((response) => {
                console.log('confirmCode:', response);
                setIsLoading(false);
            });
            console.log('confirm:', confirm);
            setLoggedIn(false);

            await AuthClient.confirmRegister(email, code);
            setIsLoading(false);
            console.log('confirm:', confirm);
            setLoggedIn(false);
        } catch (error: any) {
            console.log('error confirmCode:', error);
            if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                setError({
                    statusCode: 'NotAuthorizedExceptionConfirmed',
                    message: error.message,
                    error: error.message,
                });
            } else {
                setError({
                    statusCode: error.code,
                    message: error.message,
                    error: error.message,
                });
            }
            setIsLoading(false);
            setLoggedIn(false);
            logout();
        }
    };

    const forgotCode = async (email: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            await AuthClient.forgotPassword(email);
            setIsLoading(false);
            setLoggedIn(false);
            setEmailForgot(email);
            setSendedForgot(true);
        } catch (error: any) {
            console.log('error forgotCode:', error);
            if (error.message === 'User password cannot be reset in the current state.') {
                setError({
                    statusCode: 'NotAuthorizedExceptionForgot',
                    message: error.message,
                    error: error.message,
                });
            } else {
                setError({
                    statusCode: error.code ? error.code : error._type,
                    message: error.message ? error.message : 'Error en forgotPassword',
                    error: error.message ? error.message : 'Error en forgotPassword',
                });
            }
            setSendedForgot(false);
            setIsLoading(false);
            setLoggedIn(false);
        }
    };

    const forgotConfirm = async (email: string, code: string, password: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            if (email && code && password) {
                await AuthClient.confirmNewPassword(email, code, password);
                setEmailForgot(null);
                setSendedForgot(false);
                setPasswordOk(true);
                setIsLoading(false);
                setLoggedIn(false);
            } else {
                setError({
                    statusCode: 'fieldEmpty',
                    message: 'Se requiero correo, code y clave',
                    error: 'Se requiero correo, code y clave',
                });
            }
        } catch (error: any) {
            console.log('error forgotConfirm:', error);
            setError({
                statusCode: error.code,
                message: error.message,
                error: error.message,
            });
            setIsLoading(false);
            setLoggedIn(false);
            logout();
        }
    };

    useEffect(() => {
        const ac = new AbortController();
        async function setUp() {
            if (!isLoggedIn && error === null) {
                //console.log('entre en useEffect isLoggedIn')
                try {
                    await AuthClient.isLoggedIn();
                    setLoggedIn(true);
                    getToken();
                    getUser();
                } catch (e) {
                    setLoggedIn(false);
                    setIsLoading(false);
                }
            } else {
                //console.log('entre en useEffect ELSE')
            }
        }
        setUp();
        //When any of the Promises are aborted, Promise will reject with AbortError
        return () => ac.abort();
    }, [isLoggedIn]);

    return {
        user,
        isLoggedIn,
        loading,
        error,
        token,
        isNewUser,
        sendedForgot,
        emailForgot,
        passwordOk,
        loginEmail,
        register,
        logout,
        confirmCode,
        changePassword,
        forgotConfirm,
        forgotCode,
    };
};
