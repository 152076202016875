/**
 * Model actions define
 */

export const invitationActions = {
    INVITATION_IS_LOADING: 'INVITATION_IS_LOADING',
    INVITATION_ERROR: 'INVITATION_ERROR',
    INVITATION_RESET: 'INVITATION_RESET',
    INVITATION_NEW: 'INVITATION_NEW',
    INVITATION: 'INVITATION',
    INVITATION_LIST: 'INVITATION_LIST',
    INVITATION_GET: 'INVITATION_GET',
    INVITATION_REMOVE: 'INVITATION_REMOVE',
};

export const listInvitationActions = {
    LIST_INVITATION_IS_LOADING: 'LIST_INVITATION_IS_LOADING',
    LIST_INVITATION_ERROR: 'LIST_INVITATION_ERROR',
    LIST_INVITATION_RESET: 'LIST_INVITATION_RESET',
    LIST_INVITATION_NEW: 'LIST_INVITATION_NEW',
    LIST_INVITATION: 'LIST_INVITATION',
    LIST_INVITATION_LIST: 'LIST_INVITATION_LIST',
    LIST_INVITATION_GET: 'LIST_INVITATION_GET',
    LIST_INVITATION_REMOVE: 'LIST_INVITATION_REMOVE',
};
