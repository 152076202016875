import { listPlayerActions } from '../actions/definition';
import { IListPlayerState } from '../interface';
import { ListPlayerActionTypes } from '../interface/types';

const defaultState: IListPlayerState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listTransactionReducer = (state = defaultState, action: ListPlayerActionTypes): IListPlayerState => {
    switch (action.type) {
        case listPlayerActions.LIST_PLAYER_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listPlayerActions.LIST_PLAYER_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listPlayerActions.LIST_PLAYER_CREATED:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listPlayerActions.LIST_PLAYER:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listPlayerActions.LIST_PLAYER_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listPlayerActions.LIST_PLAYER_BY_ID:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listPlayerActions.LIST_PLAYER_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listTransactionReducer;
