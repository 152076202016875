import { broadcastActions } from '../actions/definition';
import { IBroadcastState } from '../interface';
import { BroadcastActionTypes } from '../interface/types';

const defaultState: IBroadcastState = {
    isLoading: false,
    data: null,
    error: null,
};

const broadcastReducer = (state = defaultState, action: BroadcastActionTypes): IBroadcastState => {
    switch (action.type) {
        case broadcastActions.BROADCAST_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case broadcastActions.BROADCAST_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case broadcastActions.BROADCAST_CREATED:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case broadcastActions.BROADCAST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case broadcastActions.BROADCAST_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case broadcastActions.BROADCAST_BY_ID:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case broadcastActions.BROADCAST_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default broadcastReducer;
