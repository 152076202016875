/* eslint-disable */
//import AWS from 'aws-sdk'
import { Auth } from 'aws-amplify';
//import ConfigAuth from '../../auth/index';

/**
 * Fetch AWS credentials using AWS SDK
 *
 * @param {string} token - Cognito User Pool token or Third Party acceess token
 * @param {string} provider - Name of the authenticated provider
 * @returns {Promise<object>} - Object containing properties: accessKeyId, secretAccessKey,
 * sessionToken
 */
export const getAwsCredentials = (_token: string, _provider: string): Promise<object> =>
    new Promise((_resolve, _reject) => {
        /*let providerKey = '';

        switch (provider) {
            case 'user_pool':
                providerKey = `cognito-idp.${ConfigAuth.cognitoConfig.REGION}.amazonaws.com/${ConfigAuth.cognitoConfig.USER_POOL_ID}`;
                break;
            case 'facebook':
                providerKey = 'graph.facebook.com';
                break;
            case 'google':
                providerKey = 'accounts.google.com';
                break;
            case 'amazon':
                providerKey = 'www.amazon.com';
                break;
            case 'twitter':
                providerKey = 'api.twitter.com';
                break;
            default:
                break;
        }*/

        //console.log(providerKey);

        /*AWS.config.region = ConfigAuth.cognitoConfig.REGION

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: ConfigAuth.cognitoConfig.IDENTITY_POOL_ID!,
            Logins: {
                [providerKey]: token,
            },
        })*/

        /* AWS.config.credentials.get((error: any) => {
            if (error) {
                reject(error);
            }

            const { accessKeyId, secretAccessKey, sessionToken } = AWS.config.credentials;
            const credentialSubset = { accessKeyId, secretAccessKey, sessionToken };
            resolve(credentialSubset);
        });*/
    });

/**
 * Fetch JWT token from current session
 *
 * @param {CognitoUser} currentUser - Cognito User from storage
 * @returns {Promise<string>} - Promise resolves with the JWT session ID token
 */
export const getUserToken = (currentUser) =>
    new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
            if (err) {
                reject(err);
                return;
            }
            resolve(session.getIdToken().getJwtToken());
        });
    });

async function confirmNewPassword(email, code, newPassword) {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
}

async function confirmRegister(email, code) {
    const data = await Auth.confirmSignUp(email, code, {
        forceAliasCreation: true,
    });
    return data;
}

async function forgotPassword(email) {
    await Auth.forgotPassword(email);
}

async function isLoggedIn() {
    await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    //console.log('servicio isLoggedIn:', isLoggedIn);
}

async function login(email, password) {
    const user = await Auth.signIn(email, password);
    if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        console.log('MFA code challenge');
    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('New password required');
    } else if (user.challengeName === 'MFA_SETUP') {
        Auth.setupTOTP(user);
    } else {
        // The user directly signs in
    }
    return user;
}

async function signOut() {
    await Auth.signOut();
}

async function register(email, password, firstName, lastName) {
    console.log('registre:', firstName, lastName);
    const data = await Auth.signUp({
        username: email,
        password,
        attributes: {
            email,
            name: firstName + ' ' + lastName,
            given_name: firstName,
            family_name: lastName,
        },
    });
    console.log(data);
    return data;
}

async function resendConfirm(email) {
    try {
        await Auth.resendSignUp(email);
        return true;
    } catch (error: any) {
        throw new Error(error);
    }
}

export default {
    confirmNewPassword,
    confirmRegister,
    forgotPassword,
    isLoggedIn,
    login,
    signOut,
    register,
    resendConfirm,
};
