/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
//import { Auth } from 'aws-amplify';

import configAuth from '../../auth/index';

const AmplifyConfig = {
    Auth: {
        // Amazon Cognito Identity Pool ID
        identityPoolId: configAuth.cognitoConfig.IDENTITY_POOL_ID,
        // Amazon Cognito Region
        region: configAuth.cognitoConfig.REGION,
        // Amazon Cognito User Pool ID
        userPoolId: configAuth.cognitoConfig.USER_POOL_ID,
        // Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: configAuth.cognitoConfig.APP_CLIENT_ID,
        // Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
    },
    API: {
        endpoints: [
            {
                name: configAuth.apiGateway.NAME,
                endpoint: configAuth.apiGateway.URL,
                region: configAuth.apiGateway.REGION,
            },
            {
                name: configAuth.apiGateway2.NAME,
                endpoint: configAuth.apiGateway2.URL,
                region: configAuth.apiGateway2.REGION,
            },
            {
                name: configAuth.apiGateway3.NAME,
                endpoint: configAuth.apiGateway3.URL,
                region: configAuth.apiGateway3.REGION,
            },
        ],
    },
};

export default AmplifyConfig;
