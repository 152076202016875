import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
// import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            textAlign: 'center',
            verticalAlign: 'middle',
            marginTop: 40,
            marginBottom: 40,
        },
    }),
);

const Loading: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress />
            <p>Por favor espere...</p>
        </div>
    );
};

export default Loading;
