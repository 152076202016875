/**
 * Model actions define
 */

export const gameActions = {
    GAME_IS_LOADING: 'GAME_IS_LOADING',
    GAME_ERROR: 'GAME_ERROR',
    GAME_RESET: 'GAME_RESET',
    GAME_NEW: 'GAME_NEW',
    GAME: 'GAME',
    GAME_LIST: 'GAME_LIST',
    GAME_GET: 'GAME_GET',
    GAME_REMOVE: 'GAME_REMOVE',
    GAME_EXPORT: 'GAME_EXPORT',
};

export const listGameActions = {
    LIST_GAME_IS_LOADING: 'LIST_GAME_IS_LOADING',
    LIST_GAME_ERROR: 'LIST_GAME_ERROR',
    LIST_GAME_RESET: 'LIST_GAME_RESET',
    LIST_GAME_NEW: 'LIST_GAME_NEW',
    LIST_GAME: 'LIST_GAME',
    LIST_GAME_LIST: 'LIST_GAME_LIST',
    LIST_GAME_GET: 'LIST_GAME_GET',
    LIST_GAME_REMOVE: 'LIST_GAME_REMOVE',
};

export const gameGroupActions = {
    GAME_GROUP_IS_LOADING: 'GAME_GROUP_IS_LOADING',
    GAME_GROUP_ERROR: 'GAME_GROUP_ERROR',
    GAME_GROUP_RESET: 'GAME_GROUP_RESET',
    GAME_GROUP_NEW: 'GAME_GROUP_NEW',
    GAME_GROUP_EDIT: 'GAME_GROUP_EDIT',
    GAME_GROUP: 'GAME_GROUP',
    GAME_GROUP_LIST: 'GAME_GROUP_LIST',
    GAME_GROUP_GET: 'GAME_GROUP_GET',
    GAME_GROUP_REMOVE: 'GAME_GROUP_REMOVE',
};

export const listGameGroupActions = {
    LIST_GAME_GROUP_IS_LOADING: 'LIST_GAME_GROUP_IS_LOADING',
    LIST_GAME_GROUP_ERROR: 'LIST_GAME_GROUP_ERROR',
    LIST_GAME_GROUP_RESET: 'LIST_GAME_GROUP_RESET',
    LIST_GAME_GROUP_NEW: 'LIST_GAME_GROUP_NEW',
    LIST_GAME_GROUP: 'LIST_GAME_GROUP',
    LIST_GAME_GROUP_LIST: 'LIST_GAME_GROUP_LIST',
    LIST_GAME_GROUP_GET: 'LIST_GAME_GROUP_GET',
    LIST_GAME_GROUP_REMOVE: 'LIST_GAME_GROUP_REMOVE',
};

export const gamePlayerActions = {
    GAME_PLAYER_IS_LOADING: 'GAME_PLAYER_IS_LOADING',
    GAME_PLAYER_ERROR: 'GAME_PLAYER_ERROR',
    GAME_PLAYER_RESET: 'GAME_PLAYER_RESET',
    GAME_PLAYER_CANCEL: 'GAME_PLAYER_CANCEL',
};
