import { reservationActions } from '../actions/definition';
import { IReservationState } from '../interface';
import { ReservationActionTypes } from '../interface/types';

const defaultState: IReservationState = {
    isLoading: false,
    data: null,
    error: null,
};

const reservationReducer = (state = defaultState, action: ReservationActionTypes): IReservationState => {
    switch (action.type) {
        case reservationActions.RESERVATION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case reservationActions.RESERVATION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            };
        case reservationActions.RESERVATION_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case reservationActions.RESERVATION:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case reservationActions.RESERVATION_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case reservationActions.RESERVATION_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case reservationActions.RESERVATION_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case reservationActions.RESERVATION_EXPORT:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default reservationReducer;
