import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import Amplify from 'aws-amplify';
// import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import AmplifyConfig from './app/config/provider/aws/amplify.config.js';
import { themeConfig } from './app/config/theme/theme';
import { UserProvider } from './app/context/auth-context';
import configureStore from './app/store';

const theme = responsiveFontSizes(createMuiTheme(themeConfig));
const store = configureStore();

Amplify.configure(AmplifyConfig);

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <UserProvider>
                <App />
            </UserProvider>
        </ThemeProvider>{' '}
    </Provider>,
    document.getElementById('root'),
);
