import { listFieldActions } from '../actions/definition';
import { IListFieldState } from '../interface';
import { ListFieldActionTypes } from '../interface/types';

const defaultState: IListFieldState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listFieldsReducer = (state = defaultState, action: ListFieldActionTypes): IListFieldState => {
    switch (action.type) {
        case listFieldActions.LIST_FIELD_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case listFieldActions.LIST_FIELD_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.error,
            };
        case listFieldActions.LIST_FIELD_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listFieldActions.LIST_FIELD:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links,
                meta: action.meta,
            };
        case listFieldActions.LIST_FIELD_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case listFieldActions.LIST_FIELD_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        case listFieldActions.LIST_FIELD_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
            };
        default:
            return state;
    }
};

export default listFieldsReducer;
